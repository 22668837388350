import React from "react";

import { Container, Divider, styled, Typography } from '@mui/material';
import Layout from "../components/Layout/Layout";

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const Terms: React.FC = () => {
  return (
    <Layout title="Terms and Conditions">
      <Container maxWidth="lg">
        <Root>
          <Typography variant="h4" paragraph>Terms and Conditions</Typography>
          <Divider flexItem />

          <p>
            HayApe is a collection of digital artworks (NFTs) running on
            the Ethereum network. This website is only an interface allowing
            participants to exchange digital collectibles. Users are entirely
            responsible for the safety and management of their own private Ethereum
            wallets and validating all transactions and contracts generated by this
            website before approval. Furthermore, as the HayApe smart
            contract runs on the Ethereum network, there is no ability to undo,
            reverse, or restore any transactions.
          </p>
          <p>
            This website and its connected services are provided “as is” and “as
            available” without warranty of any kind. By using this website you are
            accepting sole responsibility for any and all transactions involving HayApe digital collectibles.
          </p>
          <h2>OWNERSHIP</h2>
          <p>
            i. You Own the NFT. Each HayApe is an NFT on the Ethereum blockchain.
            When you purchase an NFT, you own the underlying HayApe, the Art,
            completely. Ownership of the NFT is mediated entirely by the Smart
            Contract and the Ethereum Network: at no point may we seize, freeze, or
            otherwise modify the ownership of any HayApe.
          </p>
          <p>
            ii. Personal Use. Subject to your continued compliance with these Terms,
            HayApe grants you a worldwide, royalty-free license to use, copy,
            and display the purchased Art, along with any extensions that you choose
            to create or use, solely forthe following purposes: (i) for your own
            personal, non-commercial use; (ii) as part of a marketplace that permits
            the purchase and sale of your HayApe / NFT, provided that the
            marketplace cryptographically verifies each HayApe owner’s rights to
            display the Art for their HayApe to ensure that only the actual owner
            can display the Art; or (iii) as part of a third party website or
            application that permits the inclusion, involvement, or participation of
            your HayApe, provided that the website/application cryptographically
            verifies each HayApe owner’s rights to display the Art for their HayApe to ensure that only the actual owner can display the Art, and provided
            that the Art is no longer visible once the owner ofthe HayApe leaves
            the website/application.
          </p>
          <p>
            iii. Commercial Use. Subject to your continued compliance with these
            Terms, HayApe grants you an unlimited, worldwide license to use,
            copy, and display the purchased Art for the purpose of creating derivative
            works based upon the Art (“Commercial Use”). Examples of such Commercial
            Use would e.g. be the use of the Art to produce and sell merchandise
            products (T-Shirts etc.) displaying copies of the Art. For the sake of
            clarity, nothing in this Section will be deemed to restrict you from (i)
            owning or operating a marketplace that permits the use and sale of HayApe's generally, provided that the marketplace cryptographically verifies
            each HayApe owner’s rights to display the Art for their HayApe to
            ensure that only the actual owner can display the Art; (ii) owning or
            operating a third party website or application that permits the inclusion,
            involvement, or participation of HayApe's generally, provided that the
            third party website or application cryptographically verifies each HayApe owner’s rights to display the Art for their HayApe to ensure that
            only the actual owner can display the Art, and provided that the Art is no
            longer visible once the owner of the Purchased HayApe leaves the
            website/application; or (iii) earning revenue from any of the foregoing.
          </p>

        </Root>
      </Container>
    </Layout>
  );
};

export default Terms;